<template>
  <div>
    <TTabs :tabs="tabs" :activeTab="$route.query.tab" @change-tab="changeTab">
      <template #detail>
        <Order orderStore="order_auction" orderItemStore="order_items_auction">
          <template #actions>
            <TButton
              content="Create purchase"
              size="sm"
              variant="outline"
              @click="showCreatePurchase = true"
              icon="cil-plus"
              v-if="!purchased && orderItems.length"
            />
          </template>
        </Order>
      </template>
      <template #logs>
        <SCardLog :logableId="id" />
      </template>
      <template #transactions>
        <SCardTransaction :objectableId="id" />
      </template>
      <template #shipment_info>
        <SCardShipmentInfo :orderId="id" :userId="auction.customer_id" />
      </template>
      <template #compensation>
        <SCardCompensationOrder :orderId="id" />
      </template>
      <template #tracking>
        <Tracking :trackings="auction.related_trackings" />
      </template>
      <template #lading_bill>
        <SCardLadingBillOfOrder :order="auction" />
      </template>
      <template #credit_card>
        <SCardCreditOrder :order="auction" orderStore="order_auction" />
      </template>
      <template #owning_box>
        <SCardOwningBoxOfOrder
          :owningBoxes="auction.owning_boxes_from_origin"
          :orderItems="orderItems"
        />
      </template>
      <template #auction_logs>
        <AuctionLogCard :orderId="id" />
      </template>
      <template #contract>
        <SCardContract :orderId="id" />
      </template>
      <template #tracking-badge>
        <CBadge color="primary">{{ auction.related_trackings.length }}</CBadge>
      </template>
      <template #lading_bill-badge>
        <CBadge color="primary">{{ ladingBills.length }}</CBadge>
      </template>
      <template #owning_box-badge>
        <CBadge color="primary">{{
          auction.owning_boxes_from_origin.length
        }}</CBadge>
      </template>
    </TTabs>
    <CreatePurchase
      :show.sync="showCreatePurchase"
      :orderItem="orderItems"
      v-if="orderItems.length > 0"
      @purchase-created="fetchItems"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Order from "../components/Order.vue";
import Tracking from "../components/Tracking.vue";
import CreatePurchase from "../../components/CreatePurchase.vue";
import AuctionLogCard from "../../components/AuctionLogCard.vue";
import organizationStore from "@/core/services/tomoni/organization.local";

export default {
  components: {
    Order,
    Tracking,
    CreatePurchase,
    AuctionLogCard,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Order detail", icon: "cis-library-books" },
        { key: "shipment_info", name: "Shipment info", icon: "cis-map" },
        { key: "contract", name: "Contract", icon: "cis-document" },
        { key: "credit_card", name: "Credit card", icon: "cis-credit-card" },
        { key: "tracking", name: "Tracking", icon: "cis-queue" },
        { key: "lading_bill", name: "Lading bill", icon: "cis-truck" },
        { key: "owning_box", name: "SKU list", icon: "cis-box" },
        { key: "compensation", name: "Compensation", icon: "cis-library" },
        { key: "auction_logs", name: "Auction logs", icon: "cis-note" },
        { key: "logs", name: "Logs", icon: "cis-note" },
        {
          key: "transactions",
          name: "Transactions arise",
          icon: "cis-speech-notes",
        },
      ],
      showCreatePurchase: false,
    };
  },
  created() {
    if (this.id) {
      this.$store
        .dispatch("order.order_auction.detail.fetch", this.id)
        .catch(() => {
          this.$router.push("/sale/orders/auction");
        });
      this.fetchItems();
    }
  },
  computed: {
    ...mapGetters({
      auction: "order.order_auction.detail",
      orderItems: "order.order_items_auction.list",
      ladingBills: "warehouse.lading_bills_of_orders.list",
    }),
    id() {
      return this.$route.params.id;
    },
    purchased() {
      if (this.organization_id != this.auction.sales_organization_id)
        return true;
      if (this.orderItems.length)
        return this.orderItems.some((x) => x.distributions?.length > 0);
      return false;
    },
    organization_id() {
      return organizationStore.get();
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
    fetchItems() {
      this.$store.dispatch("order.order_items_auction.push-query", {
        "filter[order_id]": this.id,
      });
    },
  },
};
</script>
