<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Auction logs" bold class="my-auto" />
      <TSpinner :loading="loading" />
      <TButtonRefresh class="ml-auto" @click="fetchAuctionLogs" />
    </CCardHeader>
    <CCardBody class="w-100 mx-auto">
      <div v-for="log in logs" :key="log.id">
        <CCard>
          <CCardHeader
            class="d-flex align-items-center py-2 px-2"
            color="light"
          >
            #<TMessage :content="log.id" noTranslate />
            <CBadge
              class="ml-1"
              :color="log.status == 'success' ? 'success' : 'danger'"
            >
              <TMessage
                :content="log.status == 'success' ? 'Success' : log.status"
              />
            </CBadge>
          </CCardHeader>
          <CCardBody class="p-2">
            <TTableAsForm
              title=""
              :data="log"
              :fields="fields"
              :splitLeft="4"
              class="mb-3"
            >
              <template #data="{ value }">
                <TMessage :content="value" bold noTranslate />
              </template>
              <template #type="{ value }">
                <TMessage :content="value" bold noTranslate />
              </template>
              <template #link="{ value }">
                <td>
                  <TLink v-if="value" :href="value" :content="value" />
                  <TMessage v-else :content="value" noTranslate />
                </td>
              </template>
              <template #price="{ value }">
                <TMessageMoney :amount="value" currency="JPY" />
              </template>
              <template #date_bid="{ value }">
                <TMessageDateTime :content="value" />
              </template>
              <template #date_finish="{ value }">
                <TMessageDateTime :content="value" />
              </template>
            </TTableAsForm>
          </CCardBody>
        </CCard>
      </div>
      <TMessageNoData v-if="!logs.length" />
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "link", label: "Link" },
        { key: "type", label: "Type" },
        { key: "price", label: "Price" },
        { key: "data", label: "Info" },
        { key: "date_bid", label: "Start time" },
        { key: "date_finish", label: "End time" },
      ],
    };
  },
  watch: {
    orderId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetchAuctionLogs();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      loading: "order.order_auction_logs.loading",
      logs: "order.order_auction_logs.list",
    }),
  },
  methods: {
    fetchAuctionLogs() {
      this.$store.dispatch("order.order_auction_logs.apply-query", {
        "filter[order_id]": this.orderId,
      });
    },
  },
  destroyed() {
    this.$store.commit("order.order_auction_logs.purge");
  },
};
</script>
